
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue';

@Component({
    name: "DocCenter",
    components: {
        Header,
    },
})
export default class DocCenter extends Vue {
    private contentTitle: string = "个人文档中心";
    private baseUrlIframe = process.env.VUE_APP_URL;
    private infoFlag: boolean = false;

    private created() {
        this.$nextTick(() => {
            // 获取当前用户信息
            this.$httpService.getData({}, '/apiProxy/api/frontend/users/current').then((res: any) => {
                this.infoFlag = true;
                this.$store.commit('saveUserInfo', res);
            });
        });
    }
}
